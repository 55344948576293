<div class="nagivation_block my_products_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <nav class="navbar navbar-expand-lg navbar-light" *ngIf="globals.institute_details!=null">
                    <!-- <a href="https://www.amsn.org/" target="_blank"
                        class="backwebsite_btn d-lg-none d-xl-none p-0 float-start">Visit AMSN Website</a> -->
                    <button class="navbar-toggler float-end" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="navbarNav">
                        <ul class="navbar-nav">
                            <li *ngIf="globals.permissions['assessment-list']"
                                [ngClass]="{'active':((globals.currentLink.split('/')[1]=='assessment' && (globals.currentLink.split('/')[2]!='license-pack')))}">
                                <a routerLink="/assessment/my-assessments">My Subscriptions</a>
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="false" class="products_menu"><span class="product_text">My Products <i
                                class="fa fa-caret-down ms-1"></i></span>
                        <div class="dropdown-menu" kendoTooltip position="left">
                            <div class="product_half">
                                <h4>As an Individual</h4>
                                <ul>
                                    <li>
                                        <a href="{{globals.covidUrl}}"><img
                                                src="{{globals.cndURL}}assets/images/covid.png" alt=""
                                                class="img-fluid" /><span>COVID</span>
                                        </a>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li *ngIf="dcm_present && !is_individual">
                                        <a (click)="viewIndividualDCM()"><img
                                                src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                                class="img-fluid" /><span>Digital Competency Framework</span>
                                        </a>
                                        <!-- <button class="buy_lic_btn" kendoButton><a href="https://www.amsn.org/iclp"
                                                        >Buy Licenses</a></button> -->
                                        <div class="clearfix"></div>
                                    </li>
                                    <li class="active" *ngIf="dcm_present && is_individual">
                                        <img src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                            class="img-fluid" /><span>Digital Competency Framework</span>
                                        <!-- <button class="buy_lic_btn" kendoButton><a href="https://www.amsn.org/iclp"
                                                        >Buy Licenses</a></button> -->
                                        <div class="clearfix"></div>
                                    </li>
                                </ul>
                            </div>
                            <div class="product_half">
                                <h4>As an Organization</h4>
                                <ul>
                                    <li *ngIf="connector_present">
                                        <a (click)="goToDirectConnector()"><img
                                                src="{{globals.cndURL}}assets/images/connector.png" alt=""
                                                class="img-fluid" /><span>Connector</span>
                                        </a>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li *ngIf="!connector_present">
                                        <a (click)="registerOrg()"><img
                                                src="{{globals.cndURL}}assets/images/connector.png" alt=""
                                                class="img-fluid" /><span>Register your Organization</span>
                                        </a>
                                        <div class="clearfix"></div>
                                    </li>
                                    <li *ngIf="org_dcm_present && is_individual">
                                        <a (click)="viewOrgDCM()"><img src="{{globals.cndURL}}assets/images/dcm.png"
                                                alt="" class="img-fluid" /><span>Digital Competency Framework</span>
                                        </a>
                                        <!-- <button class="buy_lic_btn" kendoButton><a href="https://www.amsn.org/iclp"
                                                        >Buy Licenses</a></button> -->
                                        <div class="clearfix"></div>
                                    </li>
                                    <li class="active" *ngIf="org_dcm_present && !is_individual">
                                        <img src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                            class="img-fluid" /><span>Digital Competency Framework</span>
                                        <!-- <button class="buy_lic_btn" kendoButton><a href="https://www.amsn.org/iclp"
                                                        >Buy Licenses</a></button> -->
                                        <div class="clearfix"></div>
                                    </li>

                                    <li *ngIf="administrator_present">
                                        <a (click)="viewAdmin()"><img
                                                src="{{globals.cndURL}}assets/images/administrator.png" alt=""
                                                class="img-fluid" /><span>Adminstration</span>
                                        </a>
                                        <div class="clearfix"></div>
                                    </li>
                                </ul>
                            </div>
                            <div class="clearfix"></div>
                            <div class="links_block"> <a href="https://faqs.ifma.uatbyopeneyes.com/home" class="feedback_a" target="_blank">FAQs</a>
                                <a href="#" class="feedback_a" target="_blank">Share a Feedback</a></div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</div>